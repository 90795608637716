<template>
  <div class="w-16 h-16 relative">
    <svg width="100%" height="100%">
      <circle r="40%" cx="50%" cy="50%" class="track" />
      <circle r="40%" cx="50%" cy="50%" class="progress" ref="progress" />
    </svg>
    <div
      class="absolute inset-0 h-full w-full flex justify-center items-center font-bold text-sm text-promy-blue-300"
    >
      {{ value }}%
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number | String,
      default: 0,
    },
  },

  watch: {
    value: {
      handler() {
        let radius = this.$refs.progress.r.baseVal.value
        let circumference = radius * 2 * Math.PI
        this.$refs.progress.style.strokeDasharray = circumference
        this.$refs.progress.style.strokeDashoffset =
          circumference - (this.value / 100) * circumference
      },
    },
  },
}
</script>

<style lang="scss">
.track {
  stroke-width: 10;
  stroke: theme('colors.promy-gray.150');
  fill: none;
}

.progress {
  stroke-width: 10;
  stroke: theme('colors.promy-green.350');
  stroke-linecap: round;
  fill: none;
  transform: rotate(270deg);
  transform-origin: center;
  transition: stroke-dashoffset 0.5s linear;
  stroke-dasharray: 160.85;
  stroke-dashoffset: 160.85;
}
</style>
