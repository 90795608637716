<template>
  <div>
    <div
      class="flex w-full items-center justify-between px-24 md:px-14 sm:px-9 py-5 fixed bg-white shadowHeader z-50"
    >
      <router-link
        :to="{
          name: 'adresse',
          params: { id_address: $route.params.id_address },
        }"
        class="flex items-center cursor-pointer"
      >
        <img
          src="/images/logo_promy_blue.svg"
          class="w-14 md:w-11 sm:w-8"
          alt="promy-logo"
        />
        <h1
          class="font-main text-3xl md:text-2xl sm-ws:text-2xl sm:text-xl font-bold text-promy-gray-300 ml-3 sm:ml-2"
        >
          Promy
        </h1>
      </router-link>
      <div class="flex items-center sm:hidden">
        <span class="pr-3 font-medium text-base text-promy-gray-350"
          >Progression</span
        >
        <ProgressionChart :value="percentProgress" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProgressionChart from './ProgressionChart.vue'
export default {
  components: {
    ProgressionChart,
  },
  computed: {
    ...mapGetters({
      currentStep: 'Tunnel/GET_STEP_NUMBER',
    }),
    percentProgress() {
      return Math.ceil(((this.currentStep - 1) * 100) / 6)
    },
  },
}
</script>

<style scoped>
.shadowHeader {
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.15);
}
</style>
